$vw-sm: 420px;
$vw-md: 1024px;
$vw-lg: 1920px;

$color_font: rgba(0, 0, 0, 0.87);
$color_font_50: rgba(0, 0, 0, 0.5);
$color_previous: #acadaf;
$color_next: #d4d4d4;
$color_background: #f5f5f5;
$color_hover: rgba(0, 0, 0, 0.05);
$color_focus: rgba(0, 0, 0, 0.03);
$color_selected: #f1f1f2;
$color_icon: #727376;
$color_fundo_logo: #373435;

$border: 1px solid #d4d4d4;
$border-radius: 8px;

$white: #f5f5f5;
$orange: #f58634;
$green: #00a859;
$blue: blue;
$red: #f44336;
$grey: #d2d3d5;
$yellow: #ffcc29;

$font-size-sm: 0.8em;
$font-size-md: 1em;
$font-size-lg: 1.2em;
$font-size-xl: 1.6em;
$font-size-xx: 2em;

$whatsapp-btn-color: #64b161;
