@tailwind base;
@tailwind components;
@tailwind utilities;

@import "vars.scss";
// @import url("https://fonts.googleapis.com/css?family=Roboto:400,700");

@font-face {
    font-family: "Quicksand";
    src: url("/assets/font/Quicksand-Medium.ttf") format("truetype");
}

*:not(.material-icons) {
    font-family: "Quicksand" !important;
}

*:focus {
    outline: $color_focus;
}

.move {
    cursor: move;
}

.pointer,
*[ng-reflect-router-link] {
    cursor: pointer;
}

.selected {
    background: rgba($color: #000000, $alpha: 0.03);
}

.hover:hover,
.pointer:hover,
.mat-list-item[ng-reflect-router-link]:hover {
    background: rgba($color: #000000, $alpha: 0.06);
}

.mirror {
    transform: scale(-1, 1);
}

html,
body {
    height: 100%;
    margin: 0;
    color: $color_font;
}

app-root {
    display: block;
    background: #f3f3f3;
    height: 100vh;
    width: 100vw;
    max-width: 100%;
    max-height: 100%;
}

app-index {
    display: block;
    margin: 0 auto;
    max-width: 1440px;
}

button.mat-button-base .mat-button-wrapper {
    display: flex;
    align-content: center;
    justify-content: space-evenly;

    mat-icon.mat-icon {
        line-height: unset;
        height: initial;
        width: initial;
    }

    mat-icon[svgicon] {
        width: 24px;
    }

    span {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        display: block;
    }
}

mat-ink-bar.mat-ink-bar {
    background-color: $orange !important;
    height: 3px;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
}

.mat-tab-label,
.mat-tab-link {
    font-size: 1.3em;
}

mat-spinner[color="orange"] {
    circle {
        stroke: $orange;
    }
}

a[color="green"],
button[color="green"] {
    background-color: $green;
    color: $white;
}

a[color="red"],
button[color="red"] {
    background-color: $red;
    color: $white;
}

a[color="orange"],
button[color="orange"] {
    background-color: $orange;
    color: $white;
}

.grid {
    display: grid !important;

    &.col,
    &-col {
        &-2 {
            grid-template-columns: auto auto;

            &-span {
                grid-column: span 2;
            }
        }

        &-3 {
            grid-template-columns: 1fr 1fr 1fr;

            &-span {
                grid-column: span 3;
            }
        }

        &-span-2 {
            grid-column: span 2;
        }
    }

    &-row {
        &-span-2 {
            grid-row: span 2;
        }

        &-span-3 {
            grid-row: span 3;
        }
    }
}

.flex {
    display: flex;

    &.column {
        flex-direction: column;
    }

    &.row {
        flex-direction: row;
    }

    &.space-around {
        justify-content: space-around;
    }
}

.scrollbar,
.ui-dialog-content,
.mat-select-panel {
    overflow: auto !important;
}

.scrollbar::-webkit-scrollbar,
.cdk-overlay-pane::-webkit-scrollbar,
.ui-dialog-content::-webkit-scrollbar,
.mat-dialog-content::-webkit-scrollbar,
.mat-dialog-container::-webkit-scrollbar,
.mat-autocomplete-panel::-webkit-scrollbar,
.mat-select-panel::-webkit-scrollbar {
    width: 6px;
    height: 6px;
}

.scrollbar::-webkit-scrollbar-track,
.cdk-overlay-pane::-webkit-scrollbar-thumb,
.ui-dialog-content::-webkit-scrollbar-track,
.mat-dialog-content::-webkit-scrollbar-track,
.mat-dialog-container::-webkit-scrollbar-track,
.mat-autocomplete-panel::-webkit-scrollbar-track,
.mat-select-panel::-webkit-scrollbar-track {
    // border-right: 1px solid #d9d9d9;
    border-radius: 4px;
    background: #f5f5f5;
}

.scrollbar::-webkit-scrollbar-thumb,
.cdk-overlay-pane::-webkit-scrollbar-thumb,
.ui-dialog-content::-webkit-scrollbar-thumb,
.mat-dialog-container::-webkit-scrollbar-thumb,
.mat-dialog-content::-webkit-scrollbar-thumb,
.mat-autocomplete-panel::-webkit-scrollbar-thumb,
.mat-select-panel::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background: #d9d9d9;
    border: 1px solid #d9d9d9;
}

.scrollbar-scroll {
    overflow: scroll !important;
}

.significado::after,
.significado::before {
    content: '"';
}

.significado span,
.significado p,
.significado {
    color: rgba($color: $orange, $alpha: 0.8);
    text-transform: lowercase;
    font-style: italic;
}

// --- begin:DIALOG

.dlg.header {
    display: flex;
    flex-direction: row;
    margin: 0 0 12px 0;

    h1,
    h2,
    p {
        font-size: 1.6em;
        font-weight: 600;
        margin: auto 18px;
    }
}

.dlg.content {
    padding: .6em 0;
}

.dlg.action {
    padding: .6em 0;
    display: flex;
    justify-content: flex-end;
    button {
        margin-left:  .6em;
    }

    .save {
        background: $orange;
        color: $white;
    }
}

.dlg.fullscreen,
.dlgFullScreen {
    display: block;
    max-height: 100vh !important;
    max-width: 100vw !important;
    height: 100% !important;
    width: 100% !important;
    overflow-y: auto;
}

@media (max-width: $vw-sm) {
    .dlg.action {
        grid-template-columns: auto;

        .save {
            grid-column: 1;
        }
    }
}

// --- END:DIALOG
